import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UiConstants } from '@core/constants/ui-constants';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { NavItem } from '@core/models/nav-item';
import { RoutingData } from '@core/models/routingData';
import { AlertService } from '@core/services/alert.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { GlobalLoadingService } from '@core/services/global-loading.service';
import { MandantLogoService } from '@core/services/mandant-logo.service';
import { MandantenService } from '@core/services/mandanten.service';
import { NavigationService } from '@core/services/navigation.service';
import { ProblemResponseService } from '@core/services/problem-response.service';
import { UserInformationService } from '@core/services/user-information.service';
import { GeneralCapabilities } from '@shared/models/generalCapabilities';
import { MandantClient } from '@shared/models/mandantenClient';
import { SyncZMRResponse } from '@shared/models/syncZMRResponse';
import { UIBenachrichtigungInfo } from '@shared/models/uIBenachrichtigungInfo';
import { AdressenService } from '@shared/services/adressen.service';
import { CapabilitiesService } from '@shared/services/capabilities.service';
import { SignalRService } from '@shared/services/signal-r.service';
import { Buffer } from 'buffer';
import { Observable, Subscription, catchError, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UnsavedChangesDialogComponent } from '../unsaved-changes-dialog/unsaved-changes-dialog.component';

/**
 * Komponente für den Inhalt der Navigationsleiste
 */
@Component({
    selector: 'k5-nav-content',
    templateUrl: './nav-content.component.html',
    styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    navItemsStart$: Observable<NavItem[]>;
    navItemsCenter$: Observable<NavItem[]>;
    navItemsEnd$: Observable<NavItem[]>;

    routerConstants = RouterLinkConstants;

    scrollTop = 0;

    mandantwechselItems$: Observable<MandantClient.InitialisierungMandantData[]> =
        this.userService.mandantwechselItems$;
    mandant = new FormControl<MandantClient.InitialisierungMandantData | null>(null, Validators.required);

    notificationPanelOpen: boolean = false;
    loadingNotifications: boolean = true;

    widthSidenavSmall = 79;
    widthSidenav = 319;
    heightMenuEntry = 56;

    currentTitle: string = '';

    constructor(
        public navigationService: NavigationService,
        public notificationService: NotificationService,
        public mandantLogoService: MandantLogoService,
        private authService: AuthService,
        private userService: UserInformationService,
        private router: Router,
        private alertService: AlertService,
        private loadingService: GlobalLoadingService,
        private problemResponseService: ProblemResponseService,
        private titleService: Title,
        private globalEditService: GlobalEditService,
        private dialog: MatDialog,
        private capabilitiesService: CapabilitiesService,
        private mandantenService: MandantenService,
        private userInformationService: UserInformationService,
        private configAssetLoaderService: ConfigAssetLoaderService,
        private signalRService: SignalRService,
        private adressenService: AdressenService
    ) {
        // Fixe Menüitems oben
        this.navItemsStart$ = this.navigationService.navItems$.pipe(
            map((navItems: NavItem[]) =>
                navItems.filter((navItem: NavItem) => navItem.position === UiConstants.POSITION_START)
            )
        );
        // Alphabetisch sortierte Menüitems in der mitte.
        this.navItemsCenter$ = this.navigationService.navItems$.pipe(
            map((navItems: NavItem[]) =>
                navItems
                    .filter((navItem: NavItem) => navItem.position === UiConstants.POSITION_CENTER)
                    .sort((a: NavItem, b: NavItem) => a.label.localeCompare(b.label))
            )
        );
        // Fixe Menüitems unten
        this.navItemsEnd$ = this.navigationService.navItems$.pipe(
            map((navItems: NavItem[]) =>
                navItems.filter((navItem: NavItem) => navItem.position === UiConstants.POSITION_END)
            )
        );
    }

    /**
     * OnInit erstellt das Menü mit Wappen und Menü-Einträgen
     */
    ngOnInit() {
        this.subscription.add(
            this.userInformationService.uiInitState$.subscribe({
                next: (initialisierung: MandantClient.CivisInitialisierung) => {
                    if (initialisierung) {
                        this.resetMandantSettingsForm();
                    }
                }
            })
        );

        // Route subscription zur Änderung des Tab-Titels und
        this.subscription.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map((event: NavigationEnd) => {
                        let route: ActivatedRoute = this.router.routerState.root;
                        let routeTitle = UiConstants.HOME_TITLE;
                        // Setzt route auf das unterste Child Element
                        while (route?.firstChild) {
                            route = route.firstChild;
                        }
                        // Liest Titel aus den Routing Daten aus
                        if (route?.snapshot?.data['title']) {
                            this.currentTitle = route.snapshot.data['title'];
                            routeTitle =
                                route.snapshot.data['title'] + ' - ' + this.userInformationService.getMandantName();
                        }
                        const routingData: RoutingData = {
                            title: routeTitle,
                            urlAfterRedirects: event.urlAfterRedirects
                        };
                        return routingData;
                    })
                )
                .subscribe((routingData: RoutingData) => {
                    this.titleService.setTitle(routingData.title);
                    this.navigationService.handleUrlChange(routingData.urlAfterRedirects);
                })
        );
    }

    /**
     * Getter für Benachrichtigungsanzahl
     */
    get badgeCount(): number {
        return this.notificationService.notificationAmount;
    }

    /**
     * Getter für den Benutzernamen
     */
    get userName(): string {
        return this.userService.getUserName();
    }

    /**
     * Gibt das Profilbild zurück
     */
    getPicture(): string {
        const accessToken = this.authService.getAccessToken();
        let picture: string;
        if (accessToken) {
            const tokenData = accessToken.split('.')[1];
            const convertedToken = JSON.parse(Buffer.from(tokenData, 'base64').toString());
            picture = convertedToken.picture;
        }
        return picture;
    }

    /**
     * Setzt die Mandant-Form zurück
     */
    resetMandantSettingsForm(): void {
        this.mandant.patchValue(this.userInformationService.getMandantFromMandantWechselItems());
    }

    /**
     * Überprüft ob ein Mandantenwechsel durchgeführt oder, wegen offener Bearbeitungen, abgebrochen werden soll
     */
    changeCurrentMandantIfNotEditingOrUserAcceptsDialog(): void {
        if (
            this.globalEditService.isEditing &&
            (this.globalEditService.getEditSectionForm() ? this.globalEditService.getEditSectionForm().dirty : true)
        ) {
            const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
            dialogRef.componentInstance.leaveSiteObservable.subscribe({
                next: (leaveSite: boolean) => {
                    // Verhindert Mandantenwechsel wenn Benutzer ungespeicherte Änderungen hat
                    if (!leaveSite) {
                        this.resetMandantSettingsForm();
                    } else {
                        this.globalEditService.switchToReadMode();
                        this.changeCurrentMandant();
                    }
                }
            });
        } else {
            if (this.globalEditService.isEditing) {
                this.globalEditService.switchToReadMode();
            }
            this.changeCurrentMandant();
        }
    }

    /**
     * Wechselt den aktuellen Mandanten
     */
    private changeCurrentMandant(): void {
        const mandantValue = this.mandant.value;
        if (mandantValue.id !== this.getCurrentMandant().id) {
            this.loadingService.startLoading('Mandant wird gewechselt ...');
            this.signalRService.removeUserAndCloseConnection();
            this.userService
                .refreshUserAndUiInformation(mandantValue.id)
                .pipe(
                    switchMap((): Observable<MandantClient.BenachrichtigungInfo | null> => {
                        // nach dem Wechsel Navigation auf Home
                        this.router.navigate([RouterLinkConstants.HOME], { queryParams: {} });
                        this.resetMandantSettingsForm();
                        this.alertService.success(`Mandant erfolgreich geändert`);
                        this.loadingService.endLoading();
                        return this.notificationService.getNotificationInfo().pipe(
                            catchError(() => {
                                return of(null);
                            })
                        );
                    }),
                    switchMap((data: UIBenachrichtigungInfo | null): Observable<GeneralCapabilities> => {
                        if (data) {
                            this.notificationService.setNewNotificationInfo(data);
                        }
                        return this.capabilitiesService.getGeneralCapabilities().pipe(
                            catchError(() => {
                                return of(null);
                            })
                        );
                    }),
                    switchMap((data: GeneralCapabilities | null): Observable<SyncZMRResponse> => {
                        this.capabilitiesService.setCapabilities(data);
                        return this.mandantenService.triggerZMRAbgleich().pipe(
                            catchError(() => {
                                return of(null);
                            })
                        );
                    }),
                    switchMap((): Observable<string> => {
                        return this.adressenService.triggerAGWRAbgleich().pipe(
                            catchError(() => {
                                return of(null);
                            })
                        );
                    })
                )
                .subscribe({
                    next: () => {
                        this.signalRService.startConnection();
                        const title: string = this.currentTitle + ' - ' + this.userInformationService.getMandantName();
                        this.titleService.setTitle(title);
                    },
                    error: (error: HttpErrorResponse) => {
                        this.resetMandantSettingsForm();
                        this.alertService.error('Fehler beim Wechseln des Mandanten', error?.message);
                        this.loadingService.endLoading();
                    }
                });
        }
    }

    /**
     * Gibt den aktuellen Mandanten zurück
     * @returns Mandant
     */
    getCurrentMandant(): MandantClient.InitialisierungMandantData {
        return this.userService.getMandant();
    }

    /**
     * Überprüft ob der User angemeldet ist
     */
    isAuthenticated(): boolean {
        return this.authService.isAuthenticated();
    }

    /**
     * Prüft ob der angemeldete Benutzer das Recht besitzt den Menüeintrag zu sehen
     * @param permissions Zu prüfendes Recht
     */
    hasPermissions(permissions: string[]): boolean {
        return this.userService.hasPermissions(permissions);
    }

    /**
     * OnDestroy löst die Subscriptions auf
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Behandelt scrolling des Menüs
     * @param event Scroll event
     */
    scrolled(event: Event): void {
        this.scrollTop = (event.target as HTMLElement).scrollTop;
    }

    /**
     * Logout
     */
    logout(): void {
        if (
            this.globalEditService.isEditing &&
            (this.globalEditService.getEditSectionForm() ? this.globalEditService.getEditSectionForm().dirty : true)
        ) {
            const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
            dialogRef.componentInstance.leaveSiteObservable.subscribe({
                next: (leaveSite: boolean) => {
                    if (leaveSite) {
                        this.globalEditService.switchToReadMode();
                        this.authService.logout();
                    }
                }
            });
        } else if (this.isAuthenticated() && !this.globalEditService.isEditing) {
            this.authService.logout();
        } else {
            if (this.globalEditService.isEditing) {
                this.globalEditService.switchToReadMode();
            }
            this.router.navigate([PathConstants.INITIALIZATION]);
        }
    }

    /**
     * Öffnet das Benachrichtigungsfenster.
     */
    toggleNotificationPanel(): void {
        this.notificationPanelOpen = !this.notificationPanelOpen;
    }

    /**
     * Öffnet die K5|Next Hilfe Seite in einem neuen Tab, wenn auf die Hilfe Menü geklickt wurde.
     */
    hilfeNavLinkClicked(): void {
        // k5|Next Hilfe Access Token abfragen
        this.mandantenService.getK5NextHilfeToken().subscribe({
            next: (token: string) => {
                // Die Seite öffnen
                window.open(`${this.configAssetLoaderService.getConfig().k5NextHilfe.baseUrl}/?t=${token}`);
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage des K5Next Hilfe Access Tokens');
            }
        });
    }

    /**
     * Feature flag #6930
     * Wenn Vorschau aktiv und die Stage nicht 'prod' ist, wird Verfahren auch herzeigt.
     * @param label
     * @returns true oder false
     */
    isNavItemVisible(label: string): boolean {
        if (label == UiConstants.VERFAHREN_LABEL) {
            return (
                this.userInformationService.getVorschauAktiv() &&
                this.configAssetLoaderService.getEnvironment() !== 'prod'
            );
        }
        return true;
    }
}
