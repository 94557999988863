import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InitializationComponent } from '@core/components/initialization/initialization.component';
import { UiConstants } from '@core/constants/ui-constants';
import { PathConstants } from './core/constants/url-constants';
import { AuthGuard } from './core/guards/auth.guard';
import { userRightsGuard } from '@core/guards/user-rights.guard';

const routes: Routes = [
    {
        path: PathConstants.HOME,
        loadChildren: () => import('./buergerservice/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: {
            title: UiConstants.HOME_TITLE
        }
    },
    {
        path: PathConstants.KONTAKTMANAGEMENT,
        loadChildren: () =>
            import('./buergerservice/kontaktmanagement/kontaktmanagement.module').then(
                (m) => m.KontaktmanagementModule
            ),
        canActivate: [
            AuthGuard,
            userRightsGuard([
                UiConstants.KONTAKT_READ,
                UiConstants.KONTAKT_PROCESS,
                UiConstants.PERSON_READ,
                UiConstants.PERSON_PROCESS
            ])
        ],
        data: { title: UiConstants.KONTAKTMANAGEMENT_TITLE, userRights: ['kontakt'] }
    },
    {
        path: PathConstants.WAHL,
        loadChildren: () => import('./buergerservice/wahl/wahl.module').then((m) => m.WahlModule),
        canActivate: [AuthGuard, userRightsGuard([UiConstants.WAHL_READ, UiConstants.WAHL_PROCESS])]
    },
    {
        path: PathConstants.CONFIGURATION,
        loadChildren: () =>
            import('./buergerservice/configuration/configuration.module').then((m) => m.ConfigurationModule),
        canActivate: [
            AuthGuard,
            userRightsGuard([
                UiConstants.ADRESSE_READ,
                UiConstants.ADRESSE_MODIFY,
                UiConstants.SPRENGEL_READ,
                UiConstants.SPRENGEL_MODIFY,
                UiConstants.TAG_MANAGE,
                UiConstants.ZMR_ABGLEICH_READ
            ])
        ],
        data: { title: UiConstants.KONFIGURATION_TITLE }
    },
    {
        path: PathConstants.USERPROFILE,
        loadChildren: () => import('./buergerservice/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
        data: { title: UiConstants.BENUTZER_TITLE }
    },
    {
        path: PathConstants.INITIALIZATION,
        component: InitializationComponent
    },
    // Redirect URI
    { path: '**', redirectTo: PathConstants.INITIALIZATION }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
