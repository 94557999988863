import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de-AT';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';

import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_SELECT_CONFIG, MatSelectConfig } from '@angular/material/select';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import {
    MAT_TOOLTIP_DEFAULT_OPTIONS,
    MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY,
    MatTooltipDefaultOptions
} from '@angular/material/tooltip';
import { UiConstants } from '@core/constants/ui-constants';
import { HttpHeaderDatenInterceptor } from '@core/interceptors/http-headerdaten.interceptor';
import { AuthService } from '@core/services/auth.service';
import { K5NEXT_DATE_FORMAT, K5NextDateAdapter } from '@k5next/ui-components';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpMandantIdInterceptor } from './core/interceptors/http-mandantId.interceptor';
import { HttpTokenInterceptor } from './core/interceptors/http-token.interceptor';
import { ConfigAssetLoaderService } from './core/services/config-asset-loader.service';
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe, UiConstants.LOCALE_AT);

export const TOOLTIP_STANDARD_OPTIONS: MatTooltipDefaultOptions = {
    showDelay: 800,
    hideDelay: 400,
    touchendHideDelay: 400,
    disableTooltipInteractivity: false
};

export const MAT_TABS_DEFAULT_CONFIG: MatTabsConfig = {
    stretchTabs: false
};

export const MAT_SELECT_DEFAULT_CONFIG: MatSelectConfig = {
    hideSingleSelectionIndicator: true
};

/**
 * Root Modul
 */
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        CoreModule,
        MaterialModule,
        RouterModule,
        SharedModule,
        MatLuxonDateModule,
        OAuthModule.forRoot()
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            deps: [ConfigAssetLoaderService, AuthService],
            multi: true,
            useFactory: (config: ConfigAssetLoaderService, authService: AuthService) => {
                return async () => {
                    // Laden der Konfiguration aus der config.json
                    await config.loadConfig();
                    // Setzen der Konfiguration im Auth-Service
                    authService.setConfiguration(config.getConfig());
                    // Starten des Auth-Service mit Code-Flow und Login
                    authService.runInitialLoginSequence();
                };
            }
        },
        {
            provide: APP_INITIALIZER,
            deps: [ConfigAssetLoaderService],
            multi: true,
            useFactory: (config: ConfigAssetLoaderService) => {
                return async () => {
                    await config.loadBuildInfo();
                };
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpMandantIdInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpHeaderDatenInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: UiConstants.LOCALE_AT },
        { provide: MAT_DATE_FORMATS, useValue: K5NEXT_DATE_FORMAT },
        { provide: DateAdapter, useClass: K5NextDateAdapter },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { ...new MatDialogConfig(), disableClose: true } },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: { ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(), ...TOOLTIP_STANDARD_OPTIONS }
        },
        { provide: MAT_TABS_CONFIG, useValue: MAT_TABS_DEFAULT_CONFIG },
        { provide: MAT_SELECT_CONFIG, useValue: MAT_SELECT_DEFAULT_CONFIG }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
