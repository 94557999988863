<!-- Website Container -->
<div class="main-container" [ngClass]="themeService.getCurrentTheme().materialClassName">
    <!-- Globales Overlay für Fehler beim Laden der Benutzereinstellungen -->
    <div class="full-height" *ngIf="!isAuthenticatedAndUserLoaded() && (loggingOut$ | async) === false">
        <div class="card-container-background">
            <div class="card-container-background-image"></div>
            <k5-info-window
                *ngIf="!errorLoadingUserInformation && (hasAuthenicationFailed$ | async) === false"
                icon="key"
                title="Anmeldung läuft ..."
                [isLoading]="true"
            >
            </k5-info-window>
            <k5-info-window
                *ngIf="errorLoadingUserInformation"
                icon="times-circle"
                iconColor="danger"
                title="Fehler beim Laden der Benutzerinformationen"
                [description]="errorMessage"
                (primaryButtonAction)="retryOrNavigateToAdmin()"
                [primaryButtonName]="allowedToAccess ? 'Erneut versuchen' : 'Zu k5|Next Admin wechseln'"
                secondaryButtonName="Logout"
                (secondaryButtonAction)="logout()"
                [isLoading]="false"
            >
            </k5-info-window>

            <k5-info-window
                *ngIf="hasAuthenicationFailed$ | async"
                icon="times-circle"
                iconColor="danger"
                title="Fehler bei der Anmeldung"
                [description]="errorMessage"
                (primaryButtonAction)="retryLogin()"
                primaryButtonName="Erneut versuchen"
                [isLoading]="false"
            >
            </k5-info-window>
        </div>
    </div>

    <!-- Globales Overlay für den Mandantenwechsel -->
    <div class="full-height" *ngIf="(loading$ | async)?.loading && (loading$ | async) as loadingInfo">
        <div class="card-container-background">
            <k5-info-window
                title="Mandantenwechsel läuft ..."
                [description]="loadingInfo.message"
                icon="house-user"
                [isLoading]="true"
            >
            </k5-info-window>
        </div>
    </div>

    <!-- Globales Overlay für den Logout -->
    <div class="full-height" *ngIf="loggingOut$ | async">
        <div class="card-container-background">
            <div class="card-container-background-image"></div>
            <k5-info-window
                title="Abmeldung läuft ..."
                description="Bitte warten Sie, während wir Sie abmelden ..."
                icon="key"
                [isLoading]="true"
            >
            </k5-info-window>
        </div>
    </div>
    <div class="full-width" *ngIf="isAuthenticatedAndUserLoaded()">
        <!-- Overlay button für das Umschalten der Navigation -->
        <button
            aria-label="Umschalten der Navigation"
            type="button"
            mat-icon-button
            color="primary"
            class="sidenav-toggle"
            (click)="navigationService.toggleSidenav()"
            *ngIf="isAuthenticatedAndUserLoaded()"
        >
            <mat-icon fontSet="fa-solid" fontIcon="fa-chevron-left" [@rotateChevron]="chevronState"></mat-icon>
        </button>

        <!-- autosize=true kann verwendet werden ist aber experimental -->
        <mat-sidenav-container class="sidenav-container" *ngIf="isAuthenticatedAndUserLoaded()">
            <!-- seitliche Navigationsleiste -->
            <mat-sidenav
                #sidenav
                id="sidenav"
                [ngClass]="{
                    sidepanel: !navigationService.sidenavSmall,
                    'sidepanel-small': navigationService.sidenavSmall && navigationService.sidenavMode === 'side'
                }"
                [mode]="navigationService.sidenavMode"
                [opened]="navigationService.sidenavOpen"
                (closedStart)="navigationService.closeSidenav()"
                [disableClose]="navigationService.sidenavMode === 'side'"
            >
                <k5-nav-content class="sidenav-content"> </k5-nav-content>
            </mat-sidenav>

            <!-- Seiteninhalt -->
            <mat-sidenav-content
                class="main-content"
                [ngClass]="{
                    'main-content-sidenav-space-sm':
                        navigationService.sidenavSmall &&
                        navigationService.sidenavMode === 'side' &&
                        isAuthenticatedAndUserLoaded(),
                    'main-content-sidenav-space':
                        !navigationService.sidenavSmall &&
                        navigationService.sidenavMode === 'side' &&
                        isAuthenticatedAndUserLoaded()
                }"
            >
                <router-outlet></router-outlet>
            </mat-sidenav-content>

            <!-- Panel für Detailsuche -->
            <mat-sidenav
                id="detailSearch"
                class="sidepanel"
                [mode]="rightSidenavigationService.rightSidenavMode"
                position="end"
                [opened]="rightSidenavigationService.rightSidenavOpen"
                *ngIf="isAuthenticatedAndUserLoaded()"
            >
                <k5-detail-search-wahl *ngIf="specifyDetailSearch.match(PATH_CONSTANTS.WAHLBESTAND)">
                </k5-detail-search-wahl>
                <k5-detail-search-kontakte *ngIf="specifyDetailSearch.match(PATH_CONSTANTS.KONTAKT_LISTE)">
                </k5-detail-search-kontakte>
            </mat-sidenav>
        </mat-sidenav-container>
    </div>
</div>
